/* eslint-disable camelcase */
import { TrainingType } from "../models/enums/trainingType.enum";

// ! defines all possible routes for react router in frontend
export const routes = {
  // general routes
  home: "/",
  admin: "/admin", // !NOTE: admin route only works in combination with backend (locally will throw 404) --> redirects to admin page
  login: "/login",
  registration: "/registration",
  register_success: "/registration/success",
  activate_account: "/registration/activate",
  events: "/veranstaltungen",
  learning_events_overview: "/veranstaltungen/lernangebote",
  event_create: "/veranstaltungen/anlegen",
  elearning_create: "/veranstaltungen/e-learning-anlegen",
  blended_learning_create: "/veranstaltungen/blended-learning-anlegen",
  my_events: "/meine-buchungen",
  my_events_details: "/meine-buchungen/details",
  my_events_details_event_id: "/meine-buchungen/details/:id",
  my_events_details_elearning: "/meine-buchungen/details-e-learning",
  my_events_details_elearning_id:
    "/meine-buchungen/details-e-learning/:event_participation_id/:id",
  pinboard_participant: "/meine-buchungen/e-learning/:id/pinnwand",
  pinboard_staff_elearning: "/veranstaltungen/e-learning-bearbeiten/:id/pinnwand",
  pinboard_staff_blended_learning:
    "/veranstaltungen/blended-learning-bearbeiten/:id/pinnwand",
  pinboard_speaker: "/meine-vortraege/details/:id/pinnwand",
  elearning_dashboard: "/e-learning-dashboard",
  elearningTest: "/e-learning-test",
  elearning_test_id: "/e-learning-test/:elearning_id/:event_participation_id",
  elearning_authentication_id: "/elearning/authentisierung/:id/:event_participation_id",
  lms: "/e-learning-lms",
  scorm_player: "/scorm-player",
  scorm_player_id: "/scorm-player/:id",
  elearning_lms: "/e-learning-lms/:booking_id",
  my_lectures: "/meine-vortraege",
  my_lectures_details: "/meine-vortraege/details",
  my_lectures_details_event_id: "/meine-vortraege/details/:id",
  my_lectures_details_elearning: "/meine-vortraege/details-e-learning",
  my_lectures_details_elearning_id: "/meine-vortraege/details-e-learning/:id",
  settings: "/einstellungen",
  event_edit: "/veranstaltungen/bearbeiten",
  event_edit_id: "/veranstaltungen/bearbeiten/:id",
  elearning_edit: "/veranstaltungen/e-learning-bearbeiten",
  elearning_edit_id: "/veranstaltungen/e-learning-bearbeiten/:id",
  blended_learning_edit: "/veranstaltungen/blended-learning-bearbeiten",
  blended_learning_edit_id: "/veranstaltungen/blended-learning-bearbeiten/:id",
  general_terms_and_conditions: "/agb",
  web_accessibility: "/barrierefreiheit",
  privacy_policy: "/datenschutz",
  contact: "/kontakt",
  legal_notice: "/impressum",
  favorites: "/favoriten",
  invoices: "/rechnungen",
  my_invoices: "/meine-rechnungen",
  testUI: "/testui",
  tasks: "/aufgaben",
  tasks_edit: "/aufgaben/bearbeiten",
  tasks_edit_id: "/aufgaben/bearbeiten/:taskId",
  tasks_new: "/aufgaben/erstellen",
  templates: "/vorlagen",
  templates_new_event: "/vorlagen/veranstaltung-erstellen",
  templates_edit_event: "/vorlagen/veranstaltung-bearbeiten",
  templates_edit_event_id: "/vorlagen/veranstaltung-bearbeiten/:id",
  templates_new_task: "/vorlagen/aufgaben-erstellen",
  templates_edit_task: "/vorlagen/aufgaben-bearbeiten",
  templates_edit_task_id: "/vorlagen/aufgaben-bearbeiten/:id",
  statistics: "/statistik",
  evaluation: "/evaluation",
  booking: "/buchung",
  checkout: "/checkout",
  bill: "/checkout/rechnung",
  afterCheckoutBill: "/checkout/rechnung/finish",
  paypal: "/checkout/paypal",
  cardPayment: "/checkout/kartenzahlung",
  afterCheckoutPaypal: "/checkout/paypal/finish",
  waiting_list_response: "/buchung/antwort/:id",
  link_confirmation: "/konto-verknuepfen",
  activityHistory: "/aktivitaetenhistorie",
  maintenance: "/wartungsarbeiten",
  forgotPassword: "/passwort-vergessen",
  resetPassword: "/passwort-reset",
  organization: "/organization",
  organization_admin_id: "/organization/:id",
  activate_organization_membership: "/membership-invite/activate",
};

export function getEditRouteBasedOnTrainingType(trainingType: TrainingType) {
  switch (trainingType) {
    case TrainingType.DefaultEvent:
      return routes.event_edit;
    case TrainingType.ELearning:
      return routes.elearning_edit;
    case TrainingType.BlendedLearning:
      return routes.blended_learning_edit;
    default:
      return routes.event_edit;
  }
}

export default routes;
