/* eslint-disable */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@material-ui/core";
import CustomTextField from "../../../../forms/CustomTextField";
import CustomDatepicker from "../../../../forms/CustomDatepicker";
import i18n from "../../../../../localization/i18n";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { useDynamicYupValidations } from "../../../../../libs/yup-validations";
import { NewEventParticipantData } from "../../../../../models/userData";
import {
  NewEventParticipantinitialData,
  userNeedsAGuardian,
} from "../../../../../pages/auth/registration/RegistrationPage.utils";
import SalutationSelect from "../../../../forms/selects/SalutationSelect";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { AppState } from "../../../../../redux";
import CustomCheckbox from "../../../../forms/CustomCheckbox";
import { getParticipants } from "../../../../../redux/participants/actions";
import { AxiosError } from "axios";
import { usersService } from "../../../../../api";

interface AddParticipantProps {
  event_id: string;
}

export const AddParticipant: React.FC<AddParticipantProps> = (props) => {
  const { event_id } = props;

  const { t } = useTranslation(["common", "snackbars"]);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [guardianRequired, setGuardianRequired] = useState(false);

  const [userMail, setUserMail] = useState<string | undefined>(undefined);

  const [emailOrUsername, setEmailOrUsername] = useState<string | undefined>(undefined);

  const [minimumAge, setMinimumAge] = useState<number>(0);
  const minimumAgeFromApi = useSelector((state: AppState) => state.minimumAge);

  const [salutation, setSalutation] = useState<number>(0);

  const currentDateLocal = new Date();
  const today = currentDateLocal.toDateString();

  const [birthday, setBirthday] = useState<MaterialUiPickersDate>();

  const [newUser, setNewUser] = useState(true);

  const participants = useSelector((state: AppState) => state.participant);

  useEffect(() => {
    if (!minimumAgeFromApi?.isLoading && minimumAgeFromApi?.minimumAge?.minimum_age) {
      setMinimumAge(minimumAgeFromApi.minimumAge.minimum_age);
    }
  }, [minimumAgeFromApi]);

  const {
    YupValidationFirstName,
    YupValidationLastName,
    YupValidationEmailOrUsername,
    YupValidationBirthday,
    YupValidationStreet,
    YupValidationStreetNumber,
    YupValidationCity,
    YupValidationZipCode,
    YupValidationSalutation,
  } = useDynamicYupValidations();

  const newEventParticipantValidationSchema = yup.object({
    first_name: YupValidationFirstName,
    last_name: YupValidationLastName,
    email_or_username: YupValidationEmailOrUsername,
    birthday: YupValidationBirthday,
    salutation: YupValidationSalutation,
    street: YupValidationStreet,
    street_number: YupValidationStreetNumber,
    city: YupValidationCity,
    zip_code: YupValidationZipCode,
  });

  const [formValues, setFormValues] = useState(NewEventParticipantinitialData);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (birthday) {
      formValues.birthday = birthday.toLocaleString();
    }
    if (emailOrUsername) {
      formValues.email_or_username = emailOrUsername;
    }
    if (salutation) {
      formValues.salutation = salutation;
    }
  }, [birthday, emailOrUsername, salutation]);

  const canAddParticipant = errors.length == 0;

  const handleFieldChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const [isLoading, setIsLoading] = useState(true);

  const evaluateForm = () => {
    try {
      newEventParticipantValidationSchema.validateSync(formValues, {
        abortEarly: false,
      });
      setErrors([]);
    } catch (validationError: any) {
      let validationErrors: any = [];
      validationError.inner.forEach((err: any) => {
        validationErrors = [...validationErrors, err];
      });
      setErrors(validationErrors);
    }
  };

  useEffect(() => {
    if (isLoading) {
      evaluateForm();
      setIsLoading(false);
    }
  }, []);

  const handleButtonClick = () => {
    try {
      newEventParticipantValidationSchema.validateSync(formValues, {
        abortEarly: false,
      });
      setErrors([]);
    } catch (validationError: any) {
      let validationErrors: any = [];
      validationError.inner.forEach((err: any) => {
        validationErrors = [...validationErrors, err];
      });
      setErrors(validationErrors);
    }
  };

  function getValidationErrors() {
    try {
      newEventParticipantValidationSchema.validateSync(formValues, {
        abortEarly: false,
      });
      return [];
    } catch (validationError: any) {
      let validationErrors: any = [];
      validationError.inner.forEach((err: any) => {
        validationErrors = [...validationErrors, err];
      });
      return validationErrors;
    }
  }

  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

  const handleSubmit = async (values: any) => {
    try {
      const requestData: NewEventParticipantData = {
        first_name: values.first_name,
        last_name: values.last_name,
        salutation: values.salutation,
        guardian_email: values.guardian_email,
        email: values.email,
        birthday: moment(values.birthday).format("DD.MM.YYYY"),
        street: values.street,
        street_number: values.street_number,
        zip_code: values.zip_code,
        city: values.city,
        password: values.password,
        event_id: event_id,
        terms_agreed_at: new Date().toLocaleDateString(),
        email_or_username: values.email_or_username,
      };

      usersService
        .registerEventParticipant(requestData)
        .then((result: any) => {
          if (result) {
            enqueueSnackbar(
              t("backendResponses.success.eventBookingCreated", { ns: "snackbars" }),
              {
                variant: "success",
              }
            );
            values = NewEventParticipantinitialData;
            dispatch(getParticipants(event_id));
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
        });

      //dispatch(getParticipants(event_id));
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  };

  useEffect(() => {
    dispatch(getParticipants(event_id));
  }, [event_id]);

  return (
    <>
      <h2>Teilnehmer anlegen und hinzufügen</h2>
      <p>
        {" "}
        Geben Sie die benötigten Informationen ein, um einen Teilnehmer zum Kurs
        hinzuzufügen.
      </p>
      <Formik
        key={i18n.language}
        initialValues={formValues}
        validationSchema={newEventParticipantValidationSchema}
        validateOnChange
        isInitialValid={false}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, handleChange, setFieldTouched, setFieldValue, resetForm }) => (
          <Form className="newEventParticipantForm">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
              <Grid item xs={12} lg={12}>
                <Field
                  as={CustomTextField}
                  style={{ width: "100%" }}
                  className="RegistrationPage"
                  fullWidth
                  name="email_or_username"
                  id="email-registration"
                  variant="filled"
                  label={t("profile.mailOrAccount", { ns: "common" })}
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const lowercaseEmail = e.target.value.toLowerCase();
                    handleChange(e.target.name)(lowercaseEmail);
                    setUserMail(lowercaseEmail);
                    setEmailOrUsername(lowercaseEmail);
                  }}
                  value={emailOrUsername}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={SalutationSelect}
                      style={{ width: "100%" }}
                      className="RegistrationPage"
                      fullWidth
                      type="name"
                      name="salutation"
                      id="salutation-registration"
                      variant="filled"
                      required
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const salut = e.target.value;
                        setSalutation(Number(salut));
                        setFieldValue("salutation", e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={CustomTextField}
                      style={{ width: "100%" }}
                      className="RegistrationPage"
                      fullWidth
                      type="name"
                      name="first_name"
                      id="first-name-registration"
                      variant="filled"
                      label={t("profile.firstName", { ns: "common" })}
                      required
                      value={formValues.first_name}
                      onChange={handleFieldChange}
                      setTouched={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={CustomTextField}
                      style={{ width: "100%" }}
                      className="RegistrationPage"
                      fullWidth
                      type="name"
                      name="last_name"
                      id="last-name-registration"
                      variant="filled"
                      label={t("profile.lastName", { ns: "common" })}
                      required
                      value={formValues.last_name}
                      onChange={handleFieldChange}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} lg={12}>
                <Field
                  as={CustomDatepicker}
                  fullWidth
                  autoComplete="birthday"
                  name="birthday"
                  id="birthday-registration"
                  label={t("profile.birthdate", { ns: "common" })}
                  value={values.birthday ?? ""}
                  customChangeHandler={(date: MaterialUiPickersDate) => {
                    setFieldValue("birthday", date);
                    setBirthday(date);
                    const dateString = date ? date.toISOString() : "";
                    setGuardianRequired(userNeedsAGuardian(dateString, minimumAge));
                  }}
                  required
                />
              </Grid>
            </Grid>
            <div className="RegistrationAddress" style={{ width: "100%" }}>
              <h3>{t("profile.addressHeader", { ns: "common" }).toUpperCase()}</h3>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={9}>
                <Field
                  as={CustomTextField}
                  fullWidth
                  id="street-registration"
                  name="street"
                  label={t("profile.street", { ns: "common" })}
                  required
                  onChange={handleFieldChange}
                  value={formValues.street}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  as={CustomTextField}
                  fullWidth
                  id="street-number-registration"
                  name="street_number"
                  label={t("profile.streetNumber", { ns: "common" })}
                  required
                  onChange={handleFieldChange}
                  value={formValues.street_number}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  as={CustomTextField}
                  fullWidth
                  id="zip-code-registration"
                  name="zip_code"
                  label={t("profile.zipCode", { ns: "common" })}
                  required
                  onChange={handleFieldChange}
                  value={formValues.zip_code}
                />
              </Grid>{" "}
              <Grid item xs={12} lg={9}>
                <Field
                  as={CustomTextField}
                  fullWidth
                  id="city-registration"
                  name="city"
                  label={t("profile.city", { ns: "common" })}
                  required
                  onChange={handleFieldChange}
                  value={formValues.city}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={0}
              alignItems="center"
              style={{ marginTop: "1.25rem" }}
            >
              <Grid item xs={9} sm={9} md={9} lg={9}>
                <CustomCheckbox
                  checked={true}
                  name={"paymentOnSite"}
                  label={"Zahlung vor Ort"}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <Button
                  id="login"
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disableElevation
                  disableRipple
                  // disabled={!canAddParticipant}
                  onClick={() => {
                    {
                      setFieldTouched("email_or_username", true, true);
                      setFieldTouched("first_name", true, true);
                      setFieldTouched("last_name", true, true);
                      setFieldTouched("birthday", true, true);
                      setFieldTouched("street", true, true);
                      setFieldTouched("street_number", true, true);
                      setFieldTouched("zip_code", true, true);
                      setFieldTouched("city", true, true);
                      evaluateForm();
                      setValidateAfterSubmit(true);
                      handleButtonClick();

                      const validationErrors = getValidationErrors();

                      const canSubmit = validationErrors.length == 0;

                      if (canSubmit) {
                        handleSubmit(values);
                        setFormValues(NewEventParticipantinitialData);
                        resetForm();
                        setEmailOrUsername(undefined);
                      }
                    }
                  }}
                >
                  Teilnehmer hinzufügen
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
