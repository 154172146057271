/* eslint-disable camelcase */
import { Box, Button, Divider, Grid, Hidden } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { NIL as NIL_UUID } from "uuid";
import { LumosEducationSvg } from "../../../assets/icons/custom-svg-components/LumosSvgCollection";
import { EdgeDates, getELearningDates } from "../../../models/elearning";
import {
  ELearningProgressStatus,
  mapCountToProgressStatus,
} from "../../../models/enums/elearningProgressStatus.enum";
import {
  ELearningParticipationStatus,
  ParticipationStatus,
} from "../../../models/enums/participationStatus.enum";
import { TrainingType } from "../../../models/enums/trainingType.enum";
import { AppState } from "../../../redux";
import { clickedEvaluationLink, getMyEvent } from "../../../redux/bookings/actions";
import { showDialog } from "../../../redux/dialogs/actions";
import { getDocumentsByEvent, setDocumentsList } from "../../../redux/documents/actions";
import usePermission from "../../../services/usePermissions";
import useValidId from "../../../components/hooks/useValidId";
import routes from "../../../routing/routes";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import {
  isExpired,
  isEventOver,
} from "../../../components/core/events/shared/checkEventIsOver";
import "../event/UserEventsPage.scss";
import EventDescription from "../../../components/core/events/my-events/details/EventDescription";
import EventDocuments from "../../../components/core/events/my-events/details/EventDocuments";
import PinboardLink from "../../../components/core/events/my-events/details/pinboard/PinboardLink";
import ShowEventDoneDialog from "../../../components/core/events/ShowEventDoneDialog";
import RevokedCertificateDialog from "../../../components/layout/dialog/RevokedCertificateDialog";
import { useTranslation } from "react-i18next";
import { createElearningRegistration } from "../../../redux/bookings/actions";
import { defaultPalette } from "../../../components/theming/defaultTheme";
import Loader from "../../../components/theming/loader/Loader";
import { checkIfStartDisabled } from "../../../utils/isStartDisabled";

export const UserELearningDetailPage: React.FC = () => {
  const { id, event_participation_id } = useParams<{
    id: string;
    event_participation_id: string;
  }>();

  const { t } = useTranslation(["common", "events", "snackbars"]);
  const dispatch = useDispatch();
  const bookings = useSelector((state: AppState) => state.booking);
  const documents = useSelector((state: AppState) => state.documents);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const { isSpeaker } = usePermission(currentUser?.user_type);
  const currentDate = new Date();
  const { isValidId } = useValidId(
    isSpeaker()
      ? routes.my_lectures_details_elearning_id
      : routes.my_events_details_elearning_id,
    id
  );

  const [edgeDates, setEdgeDates] = React.useState<EdgeDates>({
    begin: new Date(),
    end: null,
  });
  const progress = mapCountToProgressStatus(
    bookings.myEvent.elearning_started_count,
    bookings.myEvent.elearning_status === ELearningParticipationStatus.Passed,
    bookings.myEvent.status === ParticipationStatus.CertificateRevoked
  );

  // TODO duplicated code with ELearningCard
  const restrictAccessToLms = bookings.myEvent.restrict_access && !isSpeaker();
  const isELearningFinished = progress === ELearningProgressStatus.Done;

  useEffect(() => {
    if (
      bookings.myEvent.event.training_type === TrainingType.ELearning &&
      bookings.myEvent.elearning_status === ELearningParticipationStatus.Passed &&
      !bookings.myEvent.reactivated &&
      bookings.myEvent.event.evaluation_link &&
      !bookings.myEvent.user_has_clicked_evaluation_link &&
      bookings.myEvent.id !== NIL_UUID
    ) {
      dispatch(
        showDialog({
          title: t("eLearning.dialogues.evaluation.evaluationELearning", {
            ns: "events",
          }),
          message: (
            <>
              {t("eLearning.dialogues.evaluation.evaluationText", {
                ns: "events",
              })}
            </>
          ),
          confirmButtonText: t("eLearning.dialogues.evaluation.toEvaluation", {
            ns: "events",
          }),
          action: () => {
            window.open(bookings.myEvent.event.evaluation_link);
            dispatch(clickedEvaluationLink(bookings.myEvent.id, true, false));
          },
        })
      );
    } else if (
      isEventOver(bookings.myEvent.event.end) &&
      bookings.myEvent.event.training_type === TrainingType.BlendedLearning &&
      bookings.myEvent.status === ParticipationStatus.Participated &&
      bookings.myEvent.elearning_status === ELearningParticipationStatus.Passed &&
      !bookings.myEvent.reactivated &&
      bookings.myEvent.event.evaluation_link_blended_learning &&
      !bookings.myEvent.user_has_clicked_evaluation_link_blended_learning &&
      bookings.myEvent.id !== NIL_UUID
    ) {
      dispatch(
        showDialog({
          title: t("eLearning.dialogues.evaluation.evaluationBlendedLearning", {
            ns: "events",
          }),
          message: (
            <>
              {t("eLearning.dialogues.evaluation.evaluationText", {
                ns: "events",
              })}
            </>
          ),
          confirmButtonText: t("eLearning.dialogues.evaluation.toEvaluation", {
            ns: "events",
          }),
          action: () => {
            window.open(bookings.myEvent.event.evaluation_link_blended_learning);
            dispatch(clickedEvaluationLink(bookings.myEvent.id, true, true));
          },
        })
      );
    }
    setEdgeDates(getELearningDates(bookings.myEvent));
  }, [bookings.myEvent, bookings.myEvent.elearning_registration_id]);

  useEffect(() => {
    if (!bookings.isLoading) {
      dispatch(getMyEvent(event_participation_id));
    }
  }, [id, isValidId]);

  useEffect(() => {
    if (
      [
        ParticipationStatus.Booked,
        ParticipationStatus.Participated,
        ParticipationStatus.Cancelled,
      ].includes(bookings.myEvent.status)
    ) {
      dispatch(getDocumentsByEvent(bookings.myEvent.event.id, false, "elearning"));
    } else {
      dispatch(setDocumentsList([]));
    }
  }, [
    bookings.myEvent,
    bookings.myEvent.event.id,
    bookings.myEvent.status,
    bookings.myEvent.elearning_registration_id,
  ]);

  const eventTitle =
    bookings.myEvent.event.title.length > 0
      ? bookings.myEvent.event.title
      : `(${t("noTitle")})`;

  const [redirected, setRedirected] = useState(false);

  if (bookings.isLoading && !redirected) {
    return <Loader />;
  }
  return (
    <>
      <HeadingLumos>{eventTitle}</HeadingLumos>
      <Grid
        container
        style={{
          marginTop: "1.25rem",
          padding: ".5rem",
          justifyContent: "space-between",
        }}
        spacing={2}
      >
        <Grid item xs={12} md={5}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              sm={5}
              className="myevents-details-property-label myevents-details-property-container"
            >
              {t("course.courseNumber", { ns: "common" })}
            </Grid>
            <Grid item xs={12} sm={5} className="myevents-details-property-container">
              {bookings.myEvent.event.public_id}
            </Grid>

            {bookings.myEvent.event.score && bookings.myEvent.event.score > 0 ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  className="myevents-details-property-label myevents-details-property-container"
                >
                  {t("points", { ns: "events" })}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  className="myevents-details-property-container"
                >
                  {bookings.myEvent.event.score}
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>

        <Hidden mdUp>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Hidden>
        {edgeDates.begin && edgeDates.end ? (
          <Grid item xs={12} md={4}>
            <Grid container spacing={0}>
              <Grid
                item
                xs={5}
                className="myevents-details-property-label myevents-details-property-container"
              >
                {t("start", { ns: "events" })}
              </Grid>

              <Grid item xs={12} sm={5} className="myevents-details-property-container">
                {/* TODO translation time*/}
                {edgeDates.begin.toLocaleDateString()} <br />(
                {edgeDates.begin.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}{" "}
                Uhr)
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                className="myevents-details-property-label myevents-details-property-container"
              >
                {t("end", { ns: "events" })}
              </Grid>
              <Grid item xs={12} sm={5} className="myevents-details-property-container">
                {edgeDates.end.toLocaleDateString()} <br /> (
                {edgeDates.end.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}{" "}
                Uhr)
              </Grid>

              {bookings.myEvent.reactivated ? (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    className="myevents-details-property-label myevents-details-property-container"
                  >
                    {/* TODO title case */}
                    {t("eLearning.periodProlonged", { ns: "events" })}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    className="myevents-details-property-container"
                  >
                    {/* TODO translation time*/}
                    {new Date(bookings.myEvent.overall_end).toLocaleDateString()} (
                    {new Date(bookings.myEvent.overall_end).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}{" "}
                    Uhr)
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Grid>
        ) : null}

        <Grid item xs={12} md={3}>
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box>
                <strong>{t("status.progress", { ns: "events" })}: </strong>
                <span>{progress}</span>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Button
                id="start-elearning"
                disabled={checkIfStartDisabled(
                  bookings.myEvent.event.end,
                  bookings.myEvent.status,
                  bookings.myEvent.elearning_status
                )}
                onClick={() => {
                  if (
                    bookings.myEvent.status === ParticipationStatus.CertificateRevoked
                  ) {
                    dispatch(RevokedCertificateDialog());
                    return;
                  } else if (
                    isELearningFinished ||
                    isExpired(bookings.myEvent.event.end)
                  ) {
                    dispatch(ShowEventDoneDialog());
                    return;
                  }
                  if (!restrictAccessToLms || !isELearningFinished) {
                    if (bookings.myEvent.elearning_registration_id === null) {
                      dispatch(createElearningRegistration(bookings.myEvent.id));
                    }
                    if (bookings.myEventLoaded) {
                      window.open(
                        routes.elearning_test_id
                          .replace(
                            ":event_participation_id",
                            String(event_participation_id)
                          )
                          .replace(":elearning_id", id),
                        "_blank",
                        "noopener, noreferrer"
                      );
                    }
                    setRedirected(false);
                  }
                }}
              >
                <LumosEducationSvg
                  fontSize="4.5rem"
                  strokeColor={
                    edgeDates.end && edgeDates.end <= currentDate
                      ? defaultPalette.disabled?.main
                      : defaultPalette.primary?.main
                  }
                />
                {!edgeDates.end || edgeDates.end <= currentDate ? (
                  <strong>{t("eLearning.startDetailed", { ns: "events" })}</strong>
                ) : (
                  <strong>{t("eLearning.startDetailed", { ns: "events" })}</strong>
                )}
              </Button>
            </Box>
          </Box>
        </Grid>
        {bookings.myEvent.event.pinboard_is_activated
          ? bookings.myEvent.is_within_elearning_period && (
              <PinboardLink
                eventId={bookings.myEvent.event.id.toString()}
                booking={bookings.myEvent}
              />
            )
          : null}
        <EventDescription description={bookings.myEvent.event.description} />
        <EventDocuments
          documents={documents}
          no_automatic_participation_certificate={
            bookings.myEvent.event.no_automatic_participation_certificate
          }
        />
      </Grid>
    </>
  );
};

// TODO check if missing translations
